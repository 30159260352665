body {
  margin:0
}

.top-pane {
  background-color: hsl(225, 6%, 25%);
}

.pane{
  height: 50vh;
  display: flex
}

.editor-container {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  background-color: hsl(225, 6%, 25%);
}

.CodeMirror {
  height: 100% !important;
}

.editor-container.collapsed{
  flex-grow: 0;
}

.editor-container.collapsed .CodeMirror-scroll{
  position: absolute;
}

.editor-title{
  display: flex;
  justify-content: space-between;
  background-color: hsl(225, 6%, 13%);
  color: white;
  padding: .5rem .5rem .5rem 1rem;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
}

.code-mirror-wrapper{
  flex-grow: 1;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
  overflow: hidden;

}